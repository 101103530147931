import { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { LoadingSpinner } from "../../components/Common/LoadingSpinner";
import { LoginForm } from "../../components/Login/LoginForm";
import { AuthContext } from "../../context/AuthContext";
import { useLoginStyles } from "./styles";
import chia from "../../assets/chia.svg";
import logo from "../../assets/logo.svg";
import valparaiso from "../../assets/valparaiso.svg";
import "./login.css";

export const LoginView = () => {
  const classes = useLoginStyles();
  const { loading } = useContext(AuthContext);

  return (
    <Grid className={classes.mainContainer} id="login-container">
      <Grid item container className={classes.wrapper} xs={12}>
        <Grid
          item
          container
          xs={12}
          md={4}
          className={classes.welcomeContainer}
        >
          <Grid container item xs={12} spacing={2}>
            <Grid item container justifyContent="flex-start" xs={12}>
              <Typography className={classes.welcomeTitle}>
                Bienvenido{" "}
              </Typography>
            </Grid>
            <Grid item container justifyContent="flex-start" xs={12}>
              <img id="main" src={logo} alt="logo valparaiso" />
            </Grid>
            <Grid item container justifyContent="flex-start" xs={12}>
              <Typography className={classes.welcomeSubtitle}>
                ¡Hoy es un gran día!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          md={4}
          justifyContent="center"
          className={classes.formContainer}
        >
          <LoginForm />
        </Grid>
      </Grid>
      <Grid item container xs={12} className={classes.loginFooter}>
        <Grid
          item
          container
          xs={12}
          lg={8}
          className={classes.bottomTitleContainer}
          alignContent="center"
        >
          <Typography className={classes.bottomTitle}>
            Esta es una aplicación exclusiva de Valparaiso
          </Typography>
        </Grid>
        <Grid item container xs={12} lg={4} className={classes.imagesContainer}>
          <img className={classes.valparaisoIcon} src={valparaiso} alt="" />
          <img className={classes.chiaIcon} src={chia} alt="" />
        </Grid>
      </Grid>
      <LoadingSpinner loading={loading} color="white" />
    </Grid>
  );
};
