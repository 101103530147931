import { Grid } from "@material-ui/core";
import { useLoginFormUseStyles } from "./styles";

interface Props {
  message: string;
}
export const LoginError = ({ message }: Props) => {
  const classes = useLoginFormUseStyles();
  return (
    <Grid
      container
      justifyContent="center"
      className={classes.loginErrorContainer}
    >
      {message}
    </Grid>
  );
};
