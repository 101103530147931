import { useContext } from "react";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import DescriptionIcon from "@material-ui/icons/Description";
import HomeIcon from "@material-ui/icons/Home";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PeopleIcon from "@material-ui/icons/People";
import { GeneralContext } from "../../context/GeneralContext";
import { AuthContext } from "../../context/AuthContext";
import { useBottomNavigationStyles } from "./styles";

const routes: string[] = ["/home", "/requests", "/prospects"];

export const AppBottomNavigation = () => {
  const classes = useBottomNavigationStyles();

  const { handleChangeNavigation, navigationValue } =
    useContext(GeneralContext);
  const { notifications } = useContext(AuthContext);

  return (
    <BottomNavigation
      value={navigationValue}
      onChange={(event, newValue) => {
        handleChangeNavigation(newValue, routes);
      }}
      className={classes.root}
    >
      <BottomNavigationAction label="Inicio" icon={<HomeIcon />} />
      <BottomNavigationAction label="Solicitudes" icon={<DescriptionIcon />} />
      <BottomNavigationAction label="Prospectos" icon={<PeopleIcon />} />
      <BottomNavigationAction
        label=" "
        icon={
          <div style={{ display: "flex" }}>
            <MenuIcon />
            {notifications.length > 0 && (
              <FiberManualRecordIcon
                color="primary"
                className={classes.menuIcon}
              />
            )}
          </div>
        }
      />
    </BottomNavigation>
  );
};
