import { Dispatch } from "react";
import { PaginationState } from "../../interfaces/pagination.interfaces";
import { PaginationActionTypes } from "../../types/pagination.types";

export interface UsePaginationProps {
  increasePage: () => void;
  decreasePage: () => void;
  updateTotalRows: (items: number) => void;
}

export const usePagination = (
  state: PaginationState,
  dispatch: Dispatch<PaginationActionTypes>
): UsePaginationProps => {
  const increasePage = () => dispatch({ type: "increase" });
  const decreasePage = () => dispatch({ type: "decrease" });
  const updateTotalRows = (items: number) =>
    dispatch({ type: "updateTotal", payload: items });

  return {
    increasePage,
    decreasePage,
    updateTotalRows,
  };
};
