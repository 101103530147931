import {
  createContext,
  Dispatch,
  useContext,
  useReducer,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useGeneral, UseGeneralProps } from "../hooks/general/useGeneral";
import {
  usePagination,
  UsePaginationProps,
} from "../hooks/general/usePagination";
import { PaginationState } from "../interfaces/pagination.interfaces";
import {
  paginationReducer,
  PAGINATION_INITIAL_STATE,
} from "../reducers/paginationReducer";
import { PaginationActionTypes } from "../types/pagination.types";
import { AuthContext } from "./AuthContext";

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface ContextProps
  extends UseGeneralProps,
    PaginationState,
    UsePaginationProps {
  paginationDispatch: Dispatch<PaginationActionTypes>;
  isKeyBoardOpen: boolean;
  navigationValue: number | undefined;
  handleChangeNavigation: (value: number, routes: string[]) => void;
}

export const GeneralContext = createContext({} as ContextProps);

export const GeneralProvider = ({ children }: Props) => {
  const heigthRef = useRef(window.outerHeight);
  const [isKeyBoardOpen, setIsKeyBoardOpen] = useState(false);
  const [navigationValue, setNavigationValue] = useState<number | undefined>(0);
  const history = useHistory();

  const resize = () => {
    setIsKeyBoardOpen(window.outerHeight < heigthRef.current * 0.65);
  };

  const { user } = useContext(AuthContext);

  const paginationState = PAGINATION_INITIAL_STATE;

  const [pagination, paginationDispatch] = useReducer(
    paginationReducer,
    paginationState
  );

  const paginationContext = usePagination(pagination, paginationDispatch);

  const generalContext = useGeneral(user);

  const handleChangeNavigation = (value: number, routes: string[]) => {
    if (value < 3) {
      setNavigationValue(value);
      history.push(routes[value]);
    } else if (value == 3) {
      generalContext.handleDrawerToggle();
      setNavigationValue(3);
    }
  };

  window.onresize = resize;

  return (
    <GeneralContext.Provider
      value={{
        ...generalContext,
        ...pagination,
        ...paginationContext,
        paginationDispatch,
        isKeyBoardOpen,
        navigationValue,
        handleChangeNavigation,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
