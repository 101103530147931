import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthUser } from "../../interfaces/user.interfaces";

export interface UseGeneralProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  toCurrencyString: <K extends Object>(value: K) => string;
  can: (permissionName: string | string[]) => any;
  hasError: (errorsArray: any, key: string) => boolean;
  redirect: (path: string) => void;
}

export const useGeneral = (user: AuthUser): UseGeneralProps => {
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toCurrencyString = <K extends Object>(value: K) => {
    return Number(value).toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN",
    });
  };

  const hasError = <T extends Object>(errors: T, key: keyof T) =>
    Object.prototype.hasOwnProperty.call(errors, key);

  const can = (permissionName: string | string[]) => {
    const allPermissions = user.all_permissions;
    let hasPermission = null;
    if (Array.isArray(permissionName)) {
      hasPermission = permissionName.find((item) =>
        allPermissions.find((permission) => permission === item)
      );
      return hasPermission != null;
    } else {
      if (allPermissions) {
        hasPermission = allPermissions.find((item) => item === permissionName);
      }
      return hasPermission != null;
    }
  };

  const redirect = (path: string) => history.push(path);

  return {
    toCurrencyString,
    redirect,
    hasError,
    can,
    handleDrawerToggle,
    mobileOpen,
  };
};
