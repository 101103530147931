import React, { CSSProperties } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  spinnerContainer: {
    zIndex: 1500,
    position: "fixed",
    marginLeft: "auto",
    marginRight: "auto",
    top: "50%",
    left: 0,
    right: 0,
  },
}));

type ColorType = "white" | "primary";
interface Props {
  loading: boolean;
  color?: ColorType;
  styles?: CSSProperties;
}

export const LoadingSpinner = ({
  loading,
  color = "primary",
  styles,
}: Props) => {
  const classes = useStyles();

  const finalColor = (colorString: string) => {
    switch (colorString) {
      case "primary":
        return "#993399";
      case "white":
        return "white";
      default:
        return "#993399";
    }
  };
  return (
    <div className={classes.spinnerContainer} style={styles}>
      <PulseLoader size={50} color={finalColor(color)} loading={loading} />
    </div>
  );
};
