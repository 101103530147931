import HomeIcon from "@material-ui/icons/Home";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import DescriptionIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/People";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
interface SubItmemMenu {
  label: string;
  path: string;
  slug: string;
  permissionName: string;
}
export interface MenuItem {
  label: string;
  path: string;
  icon: JSX.Element;
  slug: string;
  subItemsList: SubItmemMenu[];
}

const home: MenuItem = {
  label: "Inicio",
  path: "/home",
  slug: "home",
  icon: <HomeIcon />,
  subItemsList: [
    {
      label: "Inicio",
      path: "/home",
      slug: "home",
      permissionName: "login_eva",
    },
  ],
};

const clients: MenuItem = {
  label: "Clientes",
  path: "/clients",
  slug: "clients",
  icon: <EmojiPeopleIcon />,
  subItemsList: [
    {
      label: "Clientes",
      path: "/clients",
      slug: "clients",
      permissionName: "leer_clientes",
    },
    {
      label: "Prospectos",
      path: "/prospects",
      slug: "prospects",
      permissionName: "leer_prospectos",
    },
  ],
};
const contracts: MenuItem = {
  label: "Homenajes",
  path: "/contracts",
  slug: "contracts",
  icon: <DescriptionIcon />,
  subItemsList: [
    {
      label: "Homenajes a Futuro",
      path: "/contracts",
      slug: "contracts",
      permissionName: "leer_contratos",
    },
    {
      label: "Solicitudes",
      path: "/requests",
      slug: "requests",
      permissionName: "leer_solicitudes",
    },
  ],
};

const sellers: MenuItem = {
  label: "Asociados",
  path: "",
  slug: "sellers",
  icon: <PeopleIcon />,
  subItemsList: [
    {
      label: "Asociados",
      path: "/sellers",
      slug: "sellers",
      permissionName: "leer_asociados",
    },
  ],
};

const collectors: MenuItem = {
  label: "Recuperadores",
  path: "/collectors",
  slug: "collectors",
  icon: <MotorcycleIcon />,
  subItemsList: [
    {
      label: "Recuperadores",
      path: "/collectors",
      slug: "collectors",
      permissionName: "leer_recuperadores",
    },
  ],
};

const payments: MenuItem = {
  label: "Abonos",
  path: "/payments",
  slug: "payments",
  icon: <MonetizationOnIcon />,
  subItemsList: [
    {
      label: "Abonos",
      path: "/payments",
      slug: "plans",
      permissionName: "leer_abonos",
    },
  ],
};

export const menuItems: MenuItem[] = [
  home,
  contracts,
  clients,
  sellers,
  collectors,
  payments,
];
