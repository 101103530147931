import React, { FormEvent, useContext, useState } from "react";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  Input,
  InputLabel,
  Grid,
  FormControl,
  Button,
  TextField,
} from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import { LoginError } from "./LoginError";
import { useLoginFormUseStyles } from "./styles";

export const LoginForm = () => {
  const classes = useLoginFormUseStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const { userLogin, error, message, login, onChangeUser } =
    useContext(AuthContext);

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={(e: FormEvent<HTMLFormElement>) => login(e)}>
          <Grid container justifyContent="center">
            <Grid item xs={11}>
              <TextField
                fullWidth
                name="email"
                label="Usuario"
                value={userLogin.email}
                onChange={onChangeUser}
                InputProps={{
                  style: {
                    fontSize: 20,
                    fontWeight: "bold",
                  },
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="standard-adornment-password">
                  Contraseña
                </InputLabel>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={userLogin.password}
                  onChange={onChangeUser}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        component="span"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      fontSize: 20,
                      fontWeight: "bold",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11}>
              <Typography
                className={`${classes.passwordMessage} ${classes.formControl}`}
              >
                ¿Olvidaste tu contraseña? Llama al equipo de Sistemas.
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              container
              justifyContent="center"
              className={classes.formControl}
            >
              <Button
                type="submit"
                variant="contained"
                className={classes.loginButton}
              >
                Entrar
              </Button>
            </Grid>
            <Grid item xs={11}>
              {error && <LoginError message={message} />}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};
