import React, { useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  CssBaseline,
  Grid,
  Divider,
  Drawer,
  Hidden,
  List,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { useStyles } from "./styles";
import { GeneralContext } from "../../context/GeneralContext";
import { menuItems } from "./menuItems";
import { UserCard } from "../Users/UserCard/UserCard";
import { AppBottomNavigation } from "../BottomNavigation/BottomNavigation";

interface Props {
  children: JSX.Element | JSX.Element[];
  container?: any;
}

export const ResponsiveDrawer = React.memo(({ children, container }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { mobileOpen, handleDrawerToggle, isKeyBoardOpen } =
    useContext(GeneralContext);

  const { can } = useContext(GeneralContext);

  const drawer = (
    <>
      <UserCard />
      <List style={{ overflowY: "auto" }}>
        {menuItems.map((item) => (
          <Grid container key={uuid()}>
            {can(
              item.subItemsList.map((element) => element.permissionName)
            ) && (
              <Grid container key={uuid()}>
                <Grid
                  container
                  style={{ paddingTop: 20 }}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    container
                    xs={3}
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    {item.icon}
                  </Grid>
                  <Grid item container xs={8} justifyContent="flex-start">
                    {item.subItemsList.map((sItem) => (
                      <Grid container key={uuid()}>
                        {can(sItem.permissionName) && (
                          <ListItem key={uuid()} button>
                            <NavLink
                              to={sItem.path}
                              className={classes.navLink}
                              activeClassName={classes.activeLinkItem}
                            >
                              <ListItemText
                                className={classes.listItemText}
                                primary={sItem.label}
                              />
                            </NavLink>
                          </ListItem>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Divider component="li" style={{ width: "100%" }} />
              </Grid>
            )}
          </Grid>
        ))}
      </List>
    </>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.phantom}></div>
      <main className={classes.content}>
        {children}
        {!isKeyBoardOpen && <AppBottomNavigation />}
      </main>
    </div>
  );
});
