import { makeStyles } from "@material-ui/core/styles";

export const useLoginStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100vh",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  welcomeContainer: {
    margin: theme.spacing(4),
    height: "20%",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4),
    },
    color: "white",
  },
  footerContainer: {
    color: "white",
  },
  welcomeTitle: {
    fontSize: 15,
    fontWeight: "bolder",
  },
  welcomeSubtitle: {
    fontSize: 25,
    fontWeight: "bolder",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  bottomTitle: {
    color: "white",
    fontSize: 15,
    fontWeight: "bolder",
    //position: "absolute",
    left: "3%",
    bottom: "35%",
    [theme.breakpoints.down("sm")]: {
      bottom: "25%",
    },
  },
  main: {
    width: 450,
  },
  loginFooter: {
    position: "absolute",
    bottom: "2%",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  bottomTitleContainer: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  imagesContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  footerImage: {
    marginLeft: theme.spacing(1),
    width: "50%",
  },
  formContainer: {
    [theme.breakpoints.down("sm")]: {},
  },
  valparaisoIcon: {
    width: "80px",
    height: "auto",
  },
  chiaIcon: {
    width: "80px",
    height: "auto",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "30px",
    },
  },
}));
