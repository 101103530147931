import { makeStyles } from "@material-ui/core";

export const useBottomNavigationStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    display: "flex",
    zIndex: 50,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  menuIcon: {
    fontSize: 12,
    position: "relative",
    left: -7,
  },
}));
