import { SeverityType } from "../../../interfaces/notifications.interfaces";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CancelIcon from "@material-ui/icons/Cancel";

interface Props {
  type: SeverityType;
}

export const NotificationIcon = ({ type }: Props) => {
  switch (type) {
    case "success":
      return <CheckCircleIcon color="primary" />;
    case "warning":
      return <ErrorIcon color="secondary" />;
    case "error":
      return <CancelIcon color="error" />;
  }
};
