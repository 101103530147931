import { ChangeEvent, Dispatch, FormEvent, useState } from "react";
import {
  ACCESS_TOKEN_NAME,
  backendApi,
  REFRESH_TOKEN_NAME,
} from "../../api/backendApi";
import {
  AuthUserResponse,
  LoginUserResponse,
  UserloginPost,
} from "../../interfaces/user.interfaces";
import { AuthState } from "../../reducers/authReducer";
import { AuthActionTypes } from "../../types/auth.types";

export interface UseAuthProps {
  userLogin: UserloginPost;
  login: (e: FormEvent<HTMLFormElement>) => void;
  onChangeUser: (e: ChangeEvent<HTMLInputElement>) => void;
  restoreSession: () => void;
  logout: () => void;
}

export const useAuth = (
  state: AuthState,
  dispatch: Dispatch<AuthActionTypes>
): UseAuthProps => {
  const [userLogin, setUserLogin] = useState<UserloginPost>({
    email: "",
    password: "",
  });

  const login = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: "login" });
    try {
      const response = await backendApi.post<LoginUserResponse>(
        "login",
        userLogin
      );
      const { access_token, refresh_token } = response.data;
      localStorage.setItem(ACCESS_TOKEN_NAME, access_token);
      localStorage.setItem(REFRESH_TOKEN_NAME, refresh_token);
      window.location.reload();
    } catch (error: any) {
      if (error.response.data.code !== 422) {
        dispatch({
          type: "badRequestError",
          payload: error.response.data.message,
        });
      } else {
        const { errors, message } = error.response.data;
        dispatch({ type: "loginError", payload: { errors, message } });
      }
    }
  };

  const restoreSession = async () => {
    dispatch({ type: "restoreSession" });
    try {
      const response = await backendApi.get<AuthUserResponse>("user");
      dispatch({ type: "restoreSessionSuccess", payload: response.data.data });
    } catch (error) {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      localStorage.removeItem(REFRESH_TOKEN_NAME);
      dispatch({ type: "restoreSessionError" });
    }
  };

  const logout = async () => {
    dispatch({ type: "logout" });
    try {
      await backendApi.post("logout");
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      localStorage.removeItem(REFRESH_TOKEN_NAME);
      dispatch({ type: "logoutSuccess" });
    } catch (error) {}
  };

  const onChangeUser = (e: ChangeEvent<HTMLInputElement>) => {
    setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };

  return {
    userLogin,
    login,
    onChangeUser,
    restoreSession,
    logout,
  };
};
