import { Dispatch, useEffect, useState } from "react";
import { backendApi } from "../../api/backendApi";
import {
  Notification,
  NotificationsResponse,
} from "../../interfaces/notifications.interfaces";
import { AuthState } from "../../reducers/authReducer";
import { AuthActionTypes } from "../../types/auth.types";

export interface UseNotificationsProps {
  getNotifications: () => void;
  hideUserNotification: (data: Notification) => void;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
}

export const useNotifications = (
  state: AuthState,
  dispatch: Dispatch<AuthActionTypes>
): UseNotificationsProps => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotifications = async () => {
    try {
      dispatch({ type: "getNotifications" });
      const response = await backendApi.get<NotificationsResponse>(
        "notifications/by-user"
      );
      dispatch({
        type: "getNotificationsSuccess",
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({ type: "getNotificationsError" });
    }
  };

  const hideUserNotification = (notification: Notification) => {
    backendApi.delete(`notifications/${notification.id}`).then(() => {
      const notifications = state.notifications.filter(
        (item) => item.id !== notification.id
      );
      dispatch({ type: "setNotifications", payload: notifications });
    });
  };

  return {
    anchorEl,
    handleClick,
    handleClose,
    getNotifications,
    hideUserNotification,
  };
};
