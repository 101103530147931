import { makeStyles } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";

export const generalStyles = makeStyles((theme) => ({
  mainBackGround: {
    backgroundColor: "#eeeeee",
  },
  actionButton: {
    borderTopLeftRadius: "1.3em",
    borderTopRightRadius: "1.3em",
    borderBottomLeftRadius: "1.3em",
    borderBottomRightRadius: "1.3em",
    padding: "3px 10px",
    backgroundColor: "#993399",
    marginRight: 10,
    marginBottom: 10,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "1px 10px",
    },
    "&:hover": {
      backgroundColor: purple[600],
    },
  },
  secondaryButton: {
    borderTopLeftRadius: "1.3em",
    borderTopRightRadius: "1.3em",
    borderBottomLeftRadius: "1.3em",
    borderBottomRightRadius: "1.3em",
    padding: "3px 10px",
    marginRight: 10,
    marginBottom: 10,
    backgroundColor: theme.palette.grey[600],
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "1px 10px",
    },
  },
  grayTitle: {
    color: theme.palette.grey[700],
  },
  modalCancelButton: {
    color: theme.palette.error.main,
  },
  actionModalButton: {
    color: theme.palette.warning.main,
  },
  tableHeader: {
    textTransform: "uppercase",
    color: theme.palette.grey[500],
    textAlign: "left",
  },
  autocomplete: {
    inputRoot: {
      color: "purple",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "green",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "purple",
      },
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: "black",
    backgroundColor: "#993399",
    fontSize: "2em",
  },
  appBar: {
    height: 50,
  },
  shortAppBarTittle: {
    fontSize: 25,
    fontWeight: "bold",
    color: theme.palette.grey[700],
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  appBarTittle: {
    fontSize: 25,
    fontWeight: "bold",
    color: theme.palette.grey[700],
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  formControl: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  appBarSimpleContentContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  textFieldContainer: {
    marginTop: 15,
  },
  hiddenMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hiddenDesktop: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));
