import { lazy, Suspense } from "react";
import { LoadingSpinner } from "../../components/Common/LoadingSpinner";

const Provider = lazy(() => import("../../context/DashboardContext"));
const HomeView = lazy(() => import("./HomeView"));

export const DashboardModule = () => {
  return (
    <Suspense fallback={<LoadingSpinner loading />}>
      <Provider>
        <HomeView />
      </Provider>
    </Suspense>
  );
};
