import { makeStyles } from "@material-ui/core";

export const drawerWidth = 240;

export const useUserCardStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    backgroundColor: "black",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fafafa",
    borderRight: "none",
    boxShadow: "0 0 1.3em darkgray",
  },
  userCardFullName: {
    fontSize: 14,
  },
  userCardLogoutButton: {
    fontWeight: "normal",
    color: "#FFF",
    fontSize: 10,
    padding: 0,
  },
  userCard: {
    minHeight: 200,
    zIndex: 3,
  },
  container: {
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  white: {
    color: "white",
  },
  primary: {
    color: theme.palette.primary.main,
  },
  typography: {
    marginLeft: 5,
  },
  notificationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
  },
  emptyNotificationsContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 300,
    width: 300,
  },
  emptyNotifications: {
    color: theme.palette.grey[700],
    marginRight: 10,
  },
  hiddenLg: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  showLg: {
    display: "block",
  },
}));
