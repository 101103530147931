import { makeStyles } from "@material-ui/core";

export const drawerWidth = 240;
const { innerWidth: width } = window;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#eeeeee",
    zIndex: 2,
  },
  phantom: {
    width: 0,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    zIndex: 2,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fafafa",
    borderRight: "none",
    boxShadow: "0 0 1.3em darkgray",
  },
  content: {
    paddingTop: 50,
    paddingRight: 25,
    paddingLeft: 25,
    backgroundColor: "#eeeeee",
    width: width,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    paddingBottom: 20,
    [theme.breakpoints.up("xs")]: {
      paddingTop: 20,
      paddingRight: 0,
      paddingLeft: 0,
      marginBottom: 0,
      alignItems: "flex-start",
    },
  },
  black: {
    backgroundColor: "black",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    backgroundColor: "black",
  },
  smallAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: "black",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  newUserForm: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  navLink: {
    color: "black",
    textDecoration: "none",
  },
  sidebar: {
    borderRight: "5px solid darkgray",
  },
  activeLinkItem: {
    color: "#993399",
  },
  listItemText: {
    fontWeight: "bold",
  },
  userCard: {
    minHeight: 200,
    zIndex: 3,
  },
  userCardLogoutButton: {
    fontWeight: "normal",
    color: "#FFF",
    fontSize: 10,
    padding: 0,
  },
  userCardFullName: {
    fontSize: 14,
  },
  appBarTitle: {
    color: theme.palette.grey.A200,
    fontSize: 25,
  },
  fixedAppBar: {
    display: "block",
    background: "#fafafa",
    color: theme.palette.grey[900],
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  bottomSpacer: {
    height: 100,
    position: "absolute",
    bottom: 0,
    maxWidth: "100%",
    border: "1px solid red",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
