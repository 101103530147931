import { Button } from "@material-ui/core";
import { useActionButtonStyles } from "./styles";

type ButtonSize = "small" | "medium" | "large";

interface Props {
  title?: string;
  onClick: Function;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: ButtonSize;
}

export const ActionButton = ({
  title = "Guardar",
  onClick,
  disabled,
  fullWidth = false,
  size = "medium",
}: Props) => {
  const classes = useActionButtonStyles();

  return (
    <Button
      fullWidth={fullWidth}
      disabled={disabled}
      className={classes.actionButton}
      onClick={() => onClick()}
      component="span"
      size={size}
    >
      {title}
    </Button>
  );
};

export const SecondaryButton = ({
  title = "Cancelar",
  onClick,
  disabled,
  fullWidth = false,
}: Props) => {
  const classes = useActionButtonStyles();

  return (
    <Button
      fullWidth={fullWidth}
      disabled={disabled}
      className={classes.secondaryButton}
      onClick={() => onClick()}
      component="span"
    >
      {title}
    </Button>
  );
};
