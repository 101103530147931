import { makeStyles } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";

export const useActionButtonStyles = makeStyles((theme) => ({
  actionButton: {
    borderTopLeftRadius: "1.3em",
    borderTopRightRadius: "1.3em",
    borderBottomLeftRadius: "1.3em",
    borderBottomRightRadius: "1.3em",
    padding: "3px 10px",
    backgroundColor: "#993399",
    marginRight: 10,
    marginBottom: 10,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "1px 10px",
    },
    "&:hover": {
      backgroundColor: purple[600],
    },
  },
  secondaryButton: {
    borderTopLeftRadius: "1.3em",
    borderTopRightRadius: "1.3em",
    borderBottomLeftRadius: "1.3em",
    borderBottomRightRadius: "1.3em",
    padding: "3px 10px",
    marginRight: 10,
    marginBottom: 10,
    backgroundColor: theme.palette.grey[600],
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "1px 10px",
    },
  },
}));
