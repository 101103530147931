import { createContext, useReducer } from "react";
import {
  AuthState,
  authReducer,
  AUTH_INITIAL_STATE,
} from "../reducers/authReducer";
import { useAuth, UseAuthProps } from "../hooks/auht/useAuth";
import {
  useNotifications,
  UseNotificationsProps,
} from "../hooks/auht/useNotifications";

interface Props {
  children: JSX.Element;
}

interface ContextProps extends AuthState, UseAuthProps, UseNotificationsProps {}

export const AuthContext = createContext({} as ContextProps);

export const AuthProvider = ({ children }: Props) => {
  const initialState = AUTH_INITIAL_STATE;

  const [state, dispatch] = useReducer(authReducer, initialState);

  const authContex = useAuth(state, dispatch);

  const notificationsContext = useNotifications(state, dispatch);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        ...authContex,
        ...notificationsContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
