import { Grow, Radio, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Notification } from "../../../interfaces/notifications.interfaces";
import { NotificationIcon } from "./NotificationIcon";
import { useUserCardStyles } from "./styles";

interface Props {
  notification: Notification;
}

export const NotificationItem = ({ notification }: Props) => {
  const classes = useUserCardStyles();
  const [checkeddValue, setCheckeddValue] = useState(!notification.active);

  const { hideUserNotification } = useContext(AuthContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckeddValue(event.target.checked);
  };

  useEffect(() => {
    if (checkeddValue) {
      hideUserNotification(notification);
      setCheckeddValue(false);
    }
  }, [checkeddValue]);

  return (
    <Grow in={notification.active}>
      <div className={classes.notificationContainer}>
        <NotificationIcon type={notification.severity} />
        <Typography className={classes.typography}>
          {notification.message}
        </Typography>
        <Radio checked={checkeddValue} onChange={handleChange} />
      </div>
    </Grow>
  );
};
