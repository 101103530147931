import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { generalStyles } from "../../../theme/generalStyles";
import { ActionButton, SecondaryButton } from "../ActionButtons/ActionButton";

type Sizes = "xs" | "sm" | "md" | "lg";

interface Props {
  handleClose: () => void;
  open: boolean;
  title: string;
  children: JSX.Element;
  size?: Sizes;
  actionTitle?: string;
  action: () => void;
  closeOnClikAction: boolean;
  secondaryCloseAction?: () => void;
  closeTitle?: string;
  fullScreen?: boolean;
}

export const CustomConfirmDialog = ({
  open,
  handleClose,
  title,
  children,
  size = "sm",
  actionTitle,
  action,
  closeOnClikAction,
  secondaryCloseAction,
  closeTitle,
  fullScreen,
}: Props) => {
  const classes = generalStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={size}
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle className={classes.grayTitle}>
          {title ? title.toUpperCase() : ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {closeOnClikAction ? (
            <ActionButton
              title={actionTitle || "Guardar"}
              onClick={() => {
                action();
                handleClose();
              }}
            />
          ) : (
            <ActionButton title={actionTitle || "Guardar"} onClick={action} />
          )}
          {secondaryCloseAction ? (
            <SecondaryButton
              title={closeTitle || "Cancelar"}
              onClick={() => {
                handleClose();
                secondaryCloseAction();
              }}
            />
          ) : (
            <SecondaryButton
              title={closeTitle || "Cancelar"}
              onClick={handleClose}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
