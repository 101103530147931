import { createTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";

export const theme = createTheme({
  palette: {
    primary: {
      light: purple[400],
      main: "#993399",
      dark: purple[900],
    },
  },
});
