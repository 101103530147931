import { AppState } from "./context/AppState";
import { AppRouting } from "./views/AppRouting";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme/theme";
import "./App.css";
import { HashRouter } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <HashRouter>
          <AppState>
            <AppRouting />
          </AppState>
        </HashRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
