import { makeStyles } from "@material-ui/core";
import purple from "@material-ui/core/colors/purple";

export const useLoginFormUseStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      "& > *": {
        margin: theme.spacing(2),
      },
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    fontSize: 45,
  },
  passwordMessage: {
    marginTop: theme.spacing(2),
    fontSize: 15,
  },
  paper: {
    padding: theme.spacing(1),
  },
  inputText: {
    fontSize: 25,
  },
  loginButton: {
    color: "white",
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  },
  loginErrorContainer: {
    padding: 20,
    background: theme.palette.error.light,
    borderRadius: 10,
    marginTop: 10,
  },
}));
