import { AuthProvider } from "./AuthContext";
import { GeneralProvider } from "./GeneralContext";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const AppState = ({ children }: Props) => {
  return (
    <AuthProvider>
      <GeneralProvider>{children}</GeneralProvider>
    </AuthProvider>
  );
};
