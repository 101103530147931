import axios from "axios";

export const ACCESS_TOKEN_NAME = "access_token_vf";
export const REFRESH_TOKEN_NAME = "refresh_token_vf";
export const TOKEN_TYPE = "token_type";

export const backendUri = "http://futuro.proximasistemas.com.mx/";

const baseURL = `${backendUri}api/v1`;

const ACCESS_TOKEN = localStorage.getItem(ACCESS_TOKEN_NAME);

export const remoteImages = `${backendUri}`;

axios.interceptors.request.use(
  (response) => Promise.resolve(response),
  (error) => {
    const { status } = error.response;
    switch (status) {
      case 401:
        return Promise.reject(error);
      default:
        return Promise.reject(error);
    }
  }
);

export const backendApi = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
});
