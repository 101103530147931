import { useContext, useState } from "react";
import { Avatar, Typography, Grid, Button } from "@material-ui/core";
import { remoteImages } from "../../../api/backendApi";
import "./UserCard.css";
import { AuthContext } from "../../../context/AuthContext";
import { CustomConfirmDialog } from "../../Common/ConfirmDialog/ConfirmDialog";
import { useUserCardStyles } from "./styles";
import { NotificationButton } from "./NotificationButton";

export const UserCard = () => {
  const classes = useUserCardStyles();
  const [showLogout, setShowLogout] = useState(false);
  const { logout, user } = useContext(AuthContext);

  const openLogout = () => setShowLogout(!showLogout);

  const { apellidos, nombre, imagen } = user;
  const avatarName = nombre
    ? `${nombre.substring(0, 1)} ${apellidos.substring(0, 1)}`
    : "";
  return (
    <>
      <Grid
        container
        className={`${classes.toolbar} user-card ${classes.userCard}`}
      >
        <Grid
          item
          xs={12}
          container
          alignItems="flex-start"
          justifyContent="space-between"
        >
          {imagen ? (
            <Avatar
              className={classes.large}
              src={`${remoteImages}${imagen}`}
            />
          ) : (
            <Avatar className={classes.large}>{avatarName}</Avatar>
          )}
          <NotificationButton badgecolor="secondary" />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-start">
          <Typography className={classes.userCardFullName}>
            {user.full_name}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-start">
          <Button
            component="span"
            className={classes.userCardLogoutButton}
            onClick={openLogout}
          >
            Cierra sesión
          </Button>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        size="sm"
        handleClose={openLogout}
        open={showLogout}
        actionTitle="Cerrar Sesión"
        action={logout}
        title=""
        closeOnClikAction={false}
      >
        <Grid container justifyContent="center">
          <Typography style={{ fontSize: 25 }}>¿Cerrar sesión?</Typography>
        </Grid>
      </CustomConfirmDialog>
    </>
  );
};
