import { CSSProperties, useContext } from "react";
import { Badge, IconButton, Popover, Typography } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { AuthContext } from "../../../context/AuthContext";
import { useUserCardStyles } from "./styles";
import { NotificationItem } from "./NotificationItem";

interface Props {
  iconColor?: "primary" | "white";
  badgecolor?: "default" | "primary" | "secondary" | "error" | undefined;
  hiddenLg?: boolean;
  styles?: CSSProperties;
  iconStyles?: CSSProperties;
}
export const NotificationButton = ({
  badgecolor = "primary",
  iconColor = "white",
  hiddenLg = false,
  styles,
  iconStyles,
}: Props) => {
  const { anchorEl, handleClick, handleClose, notifications } =
    useContext(AuthContext);
  const classes = useUserCardStyles();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        className={hiddenLg ? classes.hiddenLg : classes.showLg}
        onClick={handleClick}
        style={styles}
      >
        <Badge badgeContent={notifications.length} color={badgecolor}>
          {notifications.length > 0 ? (
            <NotificationsActiveIcon
              className={classes[iconColor]}
              style={iconStyles}
            />
          ) : (
            <NotificationsIcon
              className={classes[iconColor]}
              style={iconStyles}
            />
          )}
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { maxWidth: "80%", maxHeight: "70%" },
        }}
      >
        {notifications.length > 0 && (
          <div className={classes.container}>
            {notifications.map((notification) => (
              <NotificationItem
                notification={notification}
                key={notification.id}
              />
            ))}
          </div>
        )}
        {notifications.length === 0 && (
          <div className={classes.emptyNotificationsContainer}>
            <NotificationsIcon className={classes.emptyNotifications} />
            <Typography className={classes.emptyNotifications}>
              Sin notifiaciones
            </Typography>
          </div>
        )}
      </Popover>
    </>
  );
};
