import { lazy, Suspense, useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ACCESS_TOKEN_NAME } from "../api/backendApi";
import { LoadingSpinner } from "../components/Common/LoadingSpinner";
import { ResponsiveDrawer } from "../components/SideBar/SideBar";
import { AuthContext } from "../context/AuthContext";
import { DashboardModule } from "./HomeView/DashboardModule";
import { LoginView } from "./LoginView/LoginView";

const ContractsModule = lazy(
  () => import("../views/Contracts/ContractsModule")
);
const ClientsModule = lazy(() => import("../views/Clients/ClientsModule"));
const RequestsModule = lazy(() => import("../views/Requests/RequestModule"));
const ProspectModule = lazy(() => import("../views/Prospects/ProspectModule"));
const SellersModule = lazy(() => import("../views/SellersView/SellersModule"));
const CollectorsModule = lazy(
  () => import("../views/CollectorsView/CollectorsModule")
);
const PaymentsModule = lazy(
  () => import("../views/PaymentsView/PaymentsModule")
);

export const AppRouting = () => {
  const { isAuthenticated, restoreSession } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_NAME) /* && !isAuthenticated */) {
      restoreSession();
    }
  }, []);

  return (
    <Switch>
      {!isAuthenticated && (
        <>
          <Route component={LoginView} path="/login" />
          <Redirect to="/login" />
        </>
      )}
      {isAuthenticated && (
        <>
          <ResponsiveDrawer>
            <Route component={DashboardModule} exact path="/home" />
            <Route path="/contracts">
              <Suspense fallback={<LoadingSpinner loading />}>
                <ContractsModule />
              </Suspense>
            </Route>
            <Route path="/requests">
              <Suspense fallback={<LoadingSpinner loading />}>
                <RequestsModule />
              </Suspense>
            </Route>
            <Route path="/clients">
              <Suspense fallback={<LoadingSpinner loading />}>
                <ClientsModule />
              </Suspense>
            </Route>
            <Route path="/prospects">
              <Suspense fallback={<LoadingSpinner loading />}>
                <ProspectModule />
              </Suspense>
            </Route>
            <Route path="/sellers">
              <Suspense fallback={<LoadingSpinner loading />}>
                <SellersModule />
              </Suspense>
            </Route>
            <Route path="/collectors">
              <Suspense fallback={<LoadingSpinner loading />}>
                <CollectorsModule />
              </Suspense>
            </Route>
            <Route exact path="/payments">
              <Suspense fallback={<LoadingSpinner loading />}>
                <PaymentsModule />
              </Suspense>
            </Route>
            <Redirect to="/home" />
          </ResponsiveDrawer>
        </>
      )}
    </Switch>
  );
};
